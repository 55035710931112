import { useContext } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { AuthContext } from '@store/contexts';
import { handleGoogleAuth, errorGoogleAuth, handleGoogleLogout } from '@utils/auth';
import './AuthButton.css';
import { GoogleLogo } from '@components/icons';
import { callBackend } from '@utils/http';

function GoogleLogoutButton() {
    const auth = useContext(AuthContext);
    const logout = () => {
        googleLogout();
        handleGoogleLogout(auth);
    }

    return (
        <button onClick={logout} className="AuthButton">
            Sign out
        </button>
    )
}

function GoogleLoginButton() {
    const auth = useContext(AuthContext);
    const onAuthError = async () => {
        errorGoogleAuth(auth);
    };
    const getGoogleAuthTokens = async (googleResponse: any) => {
        const code = googleResponse.code;
        callBackend('auth/google/tokens', 'POST', {
            code: code
        }, (tokens) => {
            handleGoogleAuth(tokens, auth);
        });
    };
    const login = useGoogleLogin({
        onSuccess: getGoogleAuthTokens,
        onError: onAuthError,
        flow: 'auth-code'
    });
    const clickLogin = async () => {
        login();
    }

    return (
        <button onClick={clickLogin} className="AuthButton">
            <span className="AuthLogo"><GoogleLogo /></span>
            <span>Sign in with Google</span>
            <span></span>
        </button>
    )
}

export { GoogleLoginButton, GoogleLogoutButton };