import { useContext, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '@store/contexts';
import { GoogleLoginButton, GoogleLogoutButton } from '@/components/Auth/AuthButton';
import './Navbar.css';

function ProfileMenu({visibleHook}: {visibleHook: any}) {
    const auth = useContext(AuthContext);
    const close = () => {
        visibleHook.setVisible(false);
    };

    useEffect(close, [auth.loggedIn]);

    return (
        <nav className={`ProfileMenu ${visibleHook.visible ? 'open' : ''}`} ref={visibleHook.ref}>
            {auth.name !== '' ? <span>{auth.name}</span> : <></>}
            {auth.loggedIn ? <></> : <><div className="authButtonContainer">
                    <GoogleLoginButton />
                </div>
                <p className="fine-print">By signing in you agree to
                our <Link to="/privacy-policy" onClick={close}>Privacy Policy</Link> and <Link to="/terms" onClick={close}>Terms of Use</Link>.</p>
            </>}
            <hr />
            <Link to="/profile" onClick={close}>{
                auth.loggedIn ? 'Profile' : 'Usage'
            }</Link>
            <Link to="/upgrade" onClick={close}>{
                auth.loggedIn && auth.plan === 'free' ? 'Upgrade' : 'Plans'
            }</Link>
            {auth.loggedIn ?
            <>
                <br />
                <div className="authButtonContainer">
                    <GoogleLogoutButton />
                </div>
            </>
            : <></>}
        </nav>
    )
}

export default ProfileMenu;