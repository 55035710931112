import { PopupNotificationType, PopupAlertType } from './PopupNotificationContainer';

export default function sendPopup(popup: PopupNotificationType) {
    const event = new CustomEvent('popupNotification', { detail: popup });
    document.dispatchEvent(event);
}

export async function sendAlert(alert: PopupAlertType) {
    const event = new CustomEvent('popupAlert', { detail: alert });
    document.dispatchEvent(event);
    const confirmed = await new Promise<boolean>(resolve => {
        const listener = (e: any) => {
            resolve(e.detail);
            document.removeEventListener('popupAlertClose', listener);
        }
        const changeListener = (e: any) => {
            resolve(false);
            document.removeEventListener('popupAlert', changeListener);
        }
        document.addEventListener('popupAlertClose', listener);
        document.addEventListener('popupAlert', changeListener);
    });
    return confirmed;
}