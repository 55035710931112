import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '@store/contexts';
import './Navbar.css';

export default function HamburgerMenu({visibleHook}: {visibleHook: any}) {
    const auth = useContext(AuthContext);

    const close = () => {
        visibleHook.setVisible(false);
    }

    return (
        <nav className={`HamburgerMenu ${visibleHook.visible ? 'open' : ''}`} ref={visibleHook.ref}>
            <Link to="/about" onClick={close}>About</Link>
            {auth && auth.plan === 'pro' ? <></> :
            <Link to="/upgrade" className="upgradeLink" onClick={close}>Upgrade</Link>}
        </nav>
    )
}