import { useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext, ThemeContext } from '@store/contexts';
import { HamburgerMenuIcon } from '@components/icons';
import { useOutsideClick } from '@/utils/hooks';
import ProfileMenu from './ProfileMenu';
import HamburgerMenu from './HamburgerMenu';
import logo from '@assets/icons/favicon.png';
import { SunIcon, MoonIcon } from '@components/icons';
import './Navbar.css';

function Navbar() {
    const auth = useContext(AuthContext);
    const theme = useContext(ThemeContext);

    const profileButtonRef = useRef<HTMLButtonElement>(null);
    const hamburgerButtonRef = useRef<HTMLButtonElement>(null);
    const profileHook = useOutsideClick(false, profileButtonRef);
    const hamburgerHook = useOutsideClick(false, hamburgerButtonRef);
    const toggleProfileMenu = (e: any) => {
        e.preventDefault();
        hamburgerHook.setVisible(false);
        profileHook.setVisible(!profileHook.visible);
    }
    const toggleHamburgerMenu = (e: any) => {
        e.preventDefault();
        profileHook.setVisible(false);
        hamburgerHook.setVisible(!hamburgerHook.visible);
    }

    return (
        <>
            <nav className="Navbar">
                <span>
                    <button ref={hamburgerButtonRef} className={`hamburgerMenuButton ${hamburgerHook.visible ? 'selected' : ''}`} onClick={toggleHamburgerMenu}>
                        <HamburgerMenuIcon />
                    </button>
                    <Link to="/" className="mainLink">
                        <img src={logo} alt="" />
                        <span>PageThinker</span>
                    </Link>
                    <section className="hamburgerLinks">
                    <Link to="/about">About</Link>
                    </section>
                </span>
                <span>
                    <button ref={profileButtonRef} className={`profileMenuButton ${profileHook.visible ? 'selected' : ''} ${auth.pfpUrl !== '' ? 'pfp' : ''}` } onClick={toggleProfileMenu}>
                        {auth.pfpUrl !== '' ?
                            <img src={auth.pfpUrl} alt="Profile" />
                            :
                            (auth.loggedIn ?
                            'Profile'
                            :
                            'Sign in')
                        }
                    </button>
                    <button className="darkmodeButton" onClick={() => theme.setDark(!theme.dark)}>
                        {theme.dark ? <SunIcon/> : <MoonIcon/>}
                    </button>

                    {auth && auth.plan === 'pro' ? <></>
                        : <Link to="/upgrade" className="upgradeLink">
                            <span className="onlyBig">Upgrade to Pro</span>
                            <span className="onlySmall">Pro</span>
                        </Link>}
                </span>
            </nav>
            <HamburgerMenu visibleHook={hamburgerHook} />
            <ProfileMenu visibleHook={profileHook} />
        </>
    )
}

export default Navbar;