import { useState, useEffect, useCallback, useRef, RefObject } from 'react';
import { useLocation } from 'react-router-dom';

export const useMountEffect = (func: () => void) => useEffect(func, [func]);

export function useOutsideClick(initialIsVisible: boolean, toggler: RefObject<HTMLElement>) {
    const [visible, setVisible] = useState(initialIsVisible);
    const ref = useRef<HTMLElement>(null);

    const handleClickOutside = useCallback((event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const inToggler = toggler.current && toggler?.current?.contains(target);
        const inRef = ref.current && ref?.current?.contains(target);
        if (!inRef && !inToggler) {
            setVisible(false);
        }
    }, [toggler]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleClickOutside]);

    return { ref, visible, setVisible };
}

export function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export function useInterval(callback: Function, delay: number) {
    const savedCallback = useRef<Function>();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      function tick() {
        const c = savedCallback!.current!;
        c();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);

    return savedCallback;
  }