import { useState, useEffect } from 'react';
import { Checkmark, Xmark, WarningIcon, InfoIcon } from '@components/icons';
import { useMountEffect } from '@/utils/hooks';
import './PopupNotificationContainer.css';
import '@components/variables.css';

type PopupNotificationType = {
    message: string,
    type: "info" | "warning" | "error" | "success"
};
type PopupAlertType = {
    message: string,
    cancel: string,
    ok: string,
    swapColors?: boolean
}

function PopupNotification({ visible, popping, popup, click }: { visible: boolean, popping: boolean, popup: PopupNotificationType | null, click: (e: any) => void }) {
    function getIcon() {
        switch(popup?.type) {
            case "error":
                return (
                    <Xmark />
                );
            case "warning":
                return (
                    <WarningIcon />
                );
            case "success":
                return (
                    <Checkmark />
                );
            case "info":
                return (
                    <InfoIcon />
                );
            default:
                return (<></>)
        }
    }
    function getColor() {
        switch(popup?.type) {
            case "error":
                return 'var(--x-color)';
            case "warning":
                return 'var(--warning-color)';
            case "success":
                return 'var(--check-color)';
            case "info":
                return 'var(--info-color)';
            default:
                return 'var(--warning-color)';
        }
    }
    
    return (
        popup ?
        <div className={`PopupNotification ${visible ? 'visible':''} ${popping ? 'popping':''}`}>
            <span className="icon" style={{color: getColor()}}>
                { getIcon() }
            </span>
            <span>
                {popup.message}
            </span>
            <span className="close" onClick={click}>
                <Xmark />
            </span>
        </div>
        :
        <></>
    );
}

function PopupAlert({ visible, setVisible, popping, msg, click }:
    { visible: boolean, setVisible: (visible: boolean) => void, popping: boolean, msg: string, click: (e: any) => void }) {

    const [cancelMsg, setCancel] = useState<string>('Cancel');
    const [confirmMsg, setConfirm] = useState<string>('OK');
    const [swap, setSwap] = useState<boolean>(false);
    
    const confirm = () => {
        setVisible(false);
        const event = new CustomEvent('popupAlertClose', { detail: true });
        document.dispatchEvent(event);
    }
    const cancel = () => {
        setVisible(false);
        const event = new CustomEvent('popupAlertClose', { detail: false });
        document.dispatchEvent(event);
    }

    useEffect(() => {
        const listener = (e: any) => {
            setCancel(e.detail.cancel);
            setConfirm(e.detail.ok);
            setSwap(e.detail.swapColors ?? false);
        }
        document.addEventListener('popupAlert', listener);
        return () => {
            document.removeEventListener('popupAlert', listener);
        }
    })
    
    return (
        visible ?
        <div className={`PopupAlert ${visible ? 'visible':''} ${popping ? 'popping':''}`}>
            <span dangerouslySetInnerHTML={{__html: msg}}></span>
            <div className="actions">
                <button className={swap ? '' : 'red'} onClick={cancel}>{cancelMsg}</button>
                <button className={swap ? 'red' : ''} onClick={confirm}>{confirmMsg}</button>
            </div>
        </div>
        :
        <></>
    );
}

function PopupNotificationContainer() {
    const [popup, setPopup] = useState<PopupNotificationType|null>(null);
    const [showingPopup, setShowingPopup] = useState<boolean>(false);
    const [popping, setPopping] = useState<boolean>(false);
    const [alertMsg, setAlertMsg] = useState<string>('');
    const [showingAlert, setShowingAlert] = useState<boolean>(false);
    const [poppingAlert, setPoppingAlert] = useState<boolean>(false);
    let poppingTimeout: number = -1;
    let poppingAlertTimeout: number = -1;
    let removeTimeout: number = -1;

    const addPopup = (popup: PopupNotificationType) => {
        setPopping(true);
        if (!popup.type) {
            popup.type = "info";
        }
        setShowingPopup(true);
        setPopup(popup);
        window.clearTimeout(poppingTimeout);
        window.clearTimeout(removeTimeout);
        poppingTimeout = window.setTimeout(() => { setPopping(false) }, 500);
        removeTimeout = window.setTimeout(() => { removePopup() }, 5000);
    }
    const removePopup = () => {
        setPopping(false);
        setShowingPopup(false);
    }

    const addAlert = (detail: any) => {
        setPoppingAlert(true);
        setShowingAlert(true);
        setAlertMsg(detail.message);
        window.clearTimeout(poppingAlertTimeout);
        poppingAlertTimeout = window.setTimeout(() => { setPoppingAlert(false) }, 500);
    }
    const removeAlert = () => {
        setPoppingAlert(false);
        setShowingAlert(false);
    }

    useMountEffect(() => {
        const popupListener = (e: any) => {
            addPopup(e.detail);
        }
        const alertListener = (e: any) => {
            addAlert(e.detail);
        }
        document.addEventListener('popupNotification', popupListener);
        document.addEventListener('popupAlert', alertListener);

        return () => {
            document.removeEventListener('popupNotification', popupListener);
            document.removeEventListener('popupAlert', alertListener);
        }
    });

    return (
        <>
            <div className="PopupNotificationContainer">
                <PopupNotification visible={showingPopup} popping={popping} popup={popup} click={(e) => removePopup()} />
            </div>
            <div className="PopupAlertContainer">
                <PopupAlert visible={showingAlert} setVisible={setShowingAlert} popping={poppingAlert} msg={alertMsg} click={(e) => removeAlert()} />
            </div>
        </>
    );
}

export default PopupNotificationContainer;
export type { PopupNotificationType, PopupAlertType };