import { sendPopup, PopupNotificationType } from '@components/Notifications';

export type HTTPMethod = 'GET'|'POST'|'PUT'|'DELETE';

const customErrors: any = {
    'no queries remaining': 'No queries remaining',
    'bad auth': 'Bad authentication'
};

export const defaultErrorCallback = (json: any) => {
    console.error(json);
    const getPopup = (): PopupNotificationType => {
        if (customErrors[json.error]) {
            if (json.error === 'no queries remaining') {
                if (!json.data?.auth) {
                    return {message: 'Please sign in for more queries.', type: 'info'};
                } else if (json.data?.plan === 'free') {
                    return {message: 'Your monthly queries have expired. Please upgrade for more queries.', type: 'error'};
                } else {
                    return {message: 'Your monthly queries have expired.', type: 'error'};
                }
            }
            return {message: customErrors[json.error], type: 'error'};
        }
        return {message: json.error, type: 'error'};
    }
    sendPopup(getPopup());
}

/**
 * Calls the backend API with the given parameters.
 * 
 * @param endpoint the API endpoint to call
 * @param method one of 'GET', 'POST', 'PUT', or 'DELETE'
 * @param params parameters as an object to pass as the body of the request
 * @param callback function to call on the JSON object returned by the API on success
 * @param errorCallback function to call on the JSON object returned by the API on failure
 */
export async function callBackend(endpoint: string, method: HTTPMethod, params={}, callback=console.log, errorCallback=defaultErrorCallback) {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
                method: method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    // 'Authorization': `Bearer ${sessionStorage.getItem('jwt')}`
                },
                body: method === 'GET' ? null : JSON.stringify(params),
            }
        );
        
        if (response.status === 200) {
            const json = await response.json();
            if (process.env.NODE_ENV === 'development') {
                console.log(json);
            }
            callback(json);
        } else {
            console.error(response);
            const json = await response.json();
            errorCallback({
                error: json.error,
                data: json.data,
                url: `/api/${endpoint}`,
                args: params,
                status: response.status
            });
        }
    } catch (e: any) {
        const noConnectionErrors = new Set(
            ['TypeError: Failed to fetch', 'TypeError: NetworkError when attempting to fetch resource.', 'TypeError: Load failed']
        );
        errorCallback({
            error: noConnectionErrors.has(e.toString()) ? 'Could not connect to server':  e.toString(),
            url: `/api/${endpoint}`,
            args: params,
            status: noConnectionErrors.has(e.toString()) ? 503 : 400
        });
    }
}