import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from '@pages/Layout';
import { GoogleReloader } from '@components/Auth';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ScrollToTop } from '@utils/hooks';
import GlobalProvider from '@store/provider';
const AboutPage = lazy(() => import('@pages/About'));
const EditPage = lazy(() => import('@pages/EditPage'));
const ProfilePage = lazy(() => import('@pages/ProfilePage'));
const UpgradePage = lazy(() => import('@pages/Billing/UpgradePage'));
const SuccessPage = lazy(() => import('@pages/Billing/Success'));
const FailurePage = lazy(() => import('@pages/Billing/Failure'));
const ContactPage = lazy(() => import('@pages/ContactPage'));
const PrivacyPolicyPage = lazy(() => import('@pages/Legal/PrivacyPolicy'));
const TermsPage = lazy(() => import('@pages/Legal/TermsPage'));
const Error404Page = lazy(() => import('@pages/Issues/404'));



function App() {

  return (
    <GlobalProvider>
      <GoogleOAuthProvider clientId={process.env.NODE_ENV === 'production' ? 
        "372222244667-rfiiefcp4uaqmsqjlocbdfft02pp09si.apps.googleusercontent.com" :
        "465810617493-tguvtkjlregsa068365lep2gg5q3ci38.apps.googleusercontent.com"}>
        <GoogleReloader />
        <BrowserRouter>
          <ScrollToTop />
          <Suspense fallback={<Layout />}>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<EditPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/upgrade" element={<UpgradePage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/billing/success" element={<SuccessPage />} />
                <Route path="/billing/failure" element={<FailurePage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/terms" element={<TermsPage />} />
              </Route>
              <Route path="*" element={<Error404Page />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </GlobalProvider>
  );
}

export default App;
