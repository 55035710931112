import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '@store/contexts';

export default function Error404Page() {
    const theme = useContext(ThemeContext);

    return (
        <div className={`App ${theme.dark ? 'dark' : ''} wrapper`}>
            <h1>Error 404</h1>
            <p>It looks like the page you're looking for doesn't exist.
                Try going back to the <Link to='/'>home page</Link>.
            </p>
        </div>
    )
}