import { createContext } from 'react';

export type AuthProps = {
    name: string,
    setName: (name: string) => void,
    pfpUrl: string,
    setPfpUrl: (pfpUrl: string) => void,
    loggedIn: boolean,
    setLoggedIn: (loggedIn: boolean) => void,
    plan: string,
    setPlan: (plan: string) => void,
};
const defaultAuthProps = {
    name: '',
    setName: (name: string) => {},
    pfpUrl: '',
    setPfpUrl: (url: string) => {},
    loggedIn: false,
    setLoggedIn: (loggedIn: boolean) => {},
    plan: 'free',
    setPlan: (plan: string) => {},
};
const initAuthProps = () => {
    const name = '';
    const pfpUrl = '';
    const loggedIn = false;
    const plan = 'free';
    return { name, pfpUrl, loggedIn, plan };
};

export type ThemeProps = {
    dark: boolean,
    setDark: (dark: boolean) => void,
};
const defaultThemeProps = {
    dark: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
    setDark: (dark: boolean) => {},
}
const initThemeProps = () => {
    const dark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    return { dark };
};

const AuthContext = createContext<AuthProps>(defaultAuthProps);
const ThemeContext = createContext<ThemeProps>(defaultThemeProps);

export { AuthContext, initAuthProps, ThemeContext, initThemeProps };