import { callBackend } from '@utils/http';
import { AuthProps } from '@store/contexts';

const handleGoogleAuth = (googleResponse: any, auth: AuthProps) => {
    if (googleResponse?.sub) {
        auth.setLoggedIn(true);
        if (googleResponse?.name) {
            auth.setName(googleResponse.name);
        }
        if (googleResponse?.picture) {
            auth.setPfpUrl(googleResponse.picture);
        }
        callBackend('plan', 'GET', {}, (json: any) => {
            auth.setPlan(json.plan);
        });
        return true;
    }
    return false;
};
const errorGoogleAuth = (auth: AuthProps) => {
    console.error("Google authentication error");
    auth.setName('');
    auth.setPfpUrl('');
    auth.setLoggedIn(false);
    auth.setPlan('free');
}
const handleGoogleLogout = (auth: AuthProps, doQuery=true) => {
    auth.setName('');
    auth.setPfpUrl('');
    auth.setLoggedIn(false);
    auth.setPlan('free');
    if (doQuery) {
        callBackend('auth/google/logout', 'POST', {});
    }
    if (window.location.pathname === '/profile') {
        window.location.pathname = '/';
    }
}

const refreshGoogleAuth = async (auth: AuthProps) => {
    await callBackend('auth/google/refresh', 'POST', {},
        (json: any) => {
            if (!handleGoogleAuth(json, auth) && auth.loggedIn) {
                handleGoogleLogout(auth);
            }
        }
    );
}

function parseJwt (token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}
function getNameFromJwt(token: string): string {
    try {
        const parsed = parseJwt(token);
        return parsed?.name || '';
    } catch (e) {
        return '';
    }
}
function getPfpFromJwt(token: string): string {
    try {
        const parsed = parseJwt(token);
        return parsed?.picture || '';
    } catch (e) {
        return '';
    }
}

export { handleGoogleAuth, errorGoogleAuth, handleGoogleLogout, refreshGoogleAuth,
    getNameFromJwt, getPfpFromJwt };