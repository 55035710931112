import { ReactNode, useState } from 'react';
import { AuthContext, initAuthProps, ThemeContext, initThemeProps } from './contexts';

const AuthProvider = ({children}: {children: ReactNode}) => {
    const authProps = initAuthProps();
    
    const [name, setName] = useState(authProps.name);
    const [pfpUrl, setPfpUrl] = useState(authProps.pfpUrl);
    const [loggedIn, setLoggedIn] = useState(authProps.loggedIn);
    const [plan, setPlan] = useState<string>(authProps.plan);

    return (
        <AuthContext.Provider value={{
            name, setName,
            pfpUrl, setPfpUrl,
            loggedIn, setLoggedIn,
            plan, setPlan
        }}>
            {children}
        </AuthContext.Provider>
    );
};

const ThemeProvider = ({children}: {children: ReactNode}) => {
    const themeProps = initThemeProps();

    const [dark, setDark] = useState(themeProps.dark);

    return (
        <ThemeContext.Provider value={{
            dark, setDark
        }}>
            {children}
        </ThemeContext.Provider>
    );
}

const GlobalProvider = ({children}: {children: ReactNode}) => {
    return (
        <AuthProvider>
            <ThemeProvider>
                {children}
            </ThemeProvider>
        </AuthProvider>
    );
}

export default GlobalProvider;